import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import Tab from "../../tab";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import { DataTable } from "primereact/datatable";
import dayjs from "dayjs";
import { ThreeDots } from "react-loader-spinner";
import { Column } from "primereact/column";
import { SearchOutlined } from '@ant-design/icons';
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Modal,
  DatePicker,
  notification,
  TimePicker,
  Tabs,
  Pagination,
  Checkbox,
} from "antd";
import axios from "axios";
import config from "../../../config";
import { useHttp } from "../../../hooks/useHttps";
import { Select } from "antd";
import Cookie from "js-cookie";
const dateFormat = "YYYY/MM/DD";
const userRole = sessionStorage.getItem("activeRole");
const { RangePicker } = DatePicker;
const days = 86400000; //number of milliseconds in a day
const currDay = new Date();
const currDate = moment().format("YYYY-MM-DD");
const sevenDaysAgo = new Date(currDay - 7 * days);
const plus1day = new Date(currDay + 1 * days);



export default function Employers() {
  const [loadingEmployeeType, employeeTypeData] = useHttp(
    `${config.baseUrl}/v1/options/EMPLOYEE_TYPE`,
    []
  );
  const [benefitData, setBenefitData] = useState([]);
  const [filteringPagination, setFilteringPagination] = useState(false)
  const [benefitCreate, setBenefitCreate] = useState(false);
  const [scheduleFrequencyFilter, setScheduleFrequencyFilter] = useState([])
  const [listChecks, setListCheck] = useState([]);
  const [activeBenefitTab, setActiveTabBenefit] = useState("1");
  const [loadSearch, setLoadSearch] = useState(false);
  const [benefitTypeFilter, setBenefitTypeFilter] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [startDateFilter, setStartDateFilter] = useState("")

  const [employeeLevelSelected, setEmployeeLevelSelected] = useState("")
  const [positionSelected, setPositionsSelected] = useState("")
  const [departmentSelected, setDepartmentSelected] = useState([])
  const [employeeTypeSelected, setEmployeeTypeSelected] = useState([])
  const [endDateFilter, setEndDateFilter] = useState("")
  const [openRuunBenefitschedule, setOpenRunBenefitscheduled] = useState(false);
  const [triggerYear, setTriggerYear] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const [visibleDraw, setVisibleDraw] = useState(false);
  const [loadBenefits, setLoadBenefits] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [isScheduledBenefit, setIsScheduledBenefit] = useState(false);
  const [filteredEligibleEmployees, setFilteredEligibleEmployees] = useState(
    []
  );
  const [eligibleEmployeesBenefit, setEligibleEmployeesBenefit] = useState([]);
  const [updateScheduleModal, setOpenUpdateScheduleModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [failedMsgModal, setFailedMsgModal] = useState(false);
  const [departments, setDepartment] = useState([]);
  const [triggerDayOnce, setTriggerDayOnce] = useState("");
  const [triggerMonth, setMonthVar] = useState("");
  const [billersPlan, setBillersPlan] = useState([]);
  const [benefitCurrency, setBenefitCurrency] = useState("");
  const [occuringTimeHour, setOccuuringTimeHour] = useState("");
  const [dayMonth, setDayMonth] = useState("");
  const [occuringTimeMin, setOccuuringTimeMin] = useState("");
  const [scheduledTime, setScheduleTime] = useState("00:00");
  const [frequency, setFrequency] = useState("");
  const [reoccuringCheck, setReoccuring] = useState(false);

  const [currentNetwork, setCurrentNetwork] = useState("");
  const [checkStatusEti, setCheckStatusEti] = useState(false);
  const [checkStatusMtn, setCheckStatusMtn] = useState(false);
  const [positionOptions, setPositionOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [checkStatusGlo, setCheckStatusGlo] = useState(false);
  const [checkStatusAirtel, setCheckStatusAirtel] = useState(false);
  const [positionSelect, setPositionSelect] = useState([]);
  const [levelSelect, setLevelSelect] = useState([]);
  const [benefitBillerrecords, setBenefitBillersRecord] = useState([]);
  const [onUpdateData, setOnUpdateData] = useState({});
  const [showUpdateBillers, setShowUpdateBillers] = useState(false);

  const [loadFilter, setLoadFilter] = useState(false)
  const [benefitBillers, setBenefitBillers] = useState([]);
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("");
  const [BenefitSummaryDetails, setBenefitSummaryDetails] = useState([]);
  const [loaUpdateSchedule, setLoadUpdateSchedule] = useState(false);
  const [position, setPosition] = useState([]);
  const [level, setLevel] = useState([]);
  const [amountBenefit, setAmtBenefit] = useState(null);
  const [kycModal, setKycModal] = useState(true);
  const [failedErrMsgs, setFailedErrMessages] = useState([]);
  const [country, setCountry] = useState("");
  const [openBenficiarySummary, setOpenbenefitSummary] = useState(false);
  const [currency, setCurrency] = useState("");
  const [openViewBenefit, setOpenViewBenefit] = useState("");
  const [category, setCategory] = useState("");
  const [recipient, setRecipient] = useState("");
  const [userChecklists, setUserChecklist] = useState({});
  const [viewLevels, setViewLevels] = useState(null);
  const [viewpositions, setViewPositions] = useState(null);
  const [benefitStatus, setBenefitStatus] = useState("");
  const [loadbillers, setLoadBillers] = useState(false);

  const [loadingStatus, statusTypes] = useHttp(
    `${config.baseUrl}/v1/options/STATUSES`,
    []
  );


  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [rowId, setRowID] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [fieldError, setFieldError] = useState(false);
  const [period, setPeriod] = useState("")
  const [indStatus, setIndStatus] = useState("");
  const [successDecline, setSuccessDecline] = useState(false);

  var arr = [];
  const [loadingCountries, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );

  const [loadingFreq, frequencyTypes] = useHttp(
    `${config.baseUrl}/v1/options/SCHEDULE_FREQUENCY`,
    []
  );

  const [loadingType, benfitTypeDatas] = useHttp(
    `${config.baseUrl}/v1/options/BENEFIT_TYPE`,
    []
  );

  const [loadingCurrencies, currencyDatas] = useHttp(
    `${config.baseUrl}/v1/wallets/employer/${sessionStorage.getItem(
      "employer_id"
    )}`,
    []
  );

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    const updatedData = benefitData.map((item) => ({ ...item, checked }));
    setBenefitData(updatedData);
    setListCheck(updatedData);
  };

  const handleCheckboxChange = (id) => {
    const updatedData = benefitData.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setBenefitData(updatedData);
    setListCheck(updatedData?.filter((item) => item?.checked));
    const allChecked = updatedData.every((item) => item.checked);
    setSelectAll(allChecked);
  };

  const [load, setLoad] = useState(false);

  const [onboardChecklist, setOnboardChecklist] = useState(false);

  const onCancelOnboardChecklist = () => {
    setOnboardChecklist(false);
  };

  const closeFailedMsg = () => setFailedMsgModal(false);

  const convertISO = (originalDateString) => {
    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);
    // Set the time components to the desired values
    originalDate.setHours(16);
    originalDate.setMinutes(33);
    originalDate.setSeconds(49);
    originalDate.setMilliseconds(752);
    // Convert the Date object to ISO 8601 format with timezone offset
    const isoDateString = originalDate.toISOString();
    return isoDateString;
  };

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setVisibleDraw(true);
    setIsScheduledBenefit(record?.isScheduled);
    setShowUpdateBillers(false);
    setBenefitBillers(record?.benefitBillers);
    setPeriod(record.period?.slice(0, 10));



    // setReoccuring(record?.isScheduled);
    // setFrequency(record?.frequency);
    // setDayMonth(record?.triggerDay);
    // setScheduleTime(record?.triggerHour + ":" + record?.triggerMinute);
    // setOccuuringTimeMin(record?.triggerMinute);
    // setOccuuringTimeHour(record?.triggerHour);
    // setIsScheduledBenefit(record?.isScheduled);
    if (record?.isScheduled) {
      setScheduleTime(record?.triggerHour + ":" + record?.triggerMinute);
      setOccuuringTimeMin(record?.triggerMinute);
      setOccuuringTimeHour(record?.triggerHour);
      setFrequency(record?.frequency);
      setDayMonth(record?.triggerDay);
      setReoccuring(record?.isScheduled);
      setTriggerYear(record?.triggerYear);
      setMonthVar(record?.triggerMonth);

      setTriggerDayOnce(
        record?.triggerYear +
        "-" +
        "0" +
        record?.triggerMonth +
        "-" +
        record?.triggerDay
      );
    } else {
      setScheduleTime("00:00");
      setOccuuringTimeMin("");
      setOccuuringTimeHour("");
      setDayMonth("");
      setTriggerYear("");
      setMonthVar("");
      setFrequency("");
      setReoccuring(false);
    }
    setType(record.type);
    setCategory(record.category);
    setAmount(record.amountBenefit);
    setLevel(record.levels);
    setPosition(record.positions);
    setAmtBenefit(record.totalAmount);
    setCurrency(record.currency);
    setBenefitCurrency(record.benefitCurrency);
    setBenefitBillersRecord(record?.benefitBillers);
    setRecipient(record.recipient);
    setCountry(record.country);
    setBenefitStatus(record.status);
    setViewLevels(record.levels);
    setViewPositions(record.positions);
    setType(record?.type === "DATA BUNDLE" ? "DATA_BUNDLE" : record?.type);
    setIndStatus(record?.status);
    setOnUpdateData(record);
  };

  const closeDrawer = () => {
    closeAdd();
    setVisibleDraw(false);
  };

  const closeAdd = () => {
    setBenefitCreate(false);
    setCategory("");
    setAmount("");
    setCurrency("");
    setPeriod("")
    setCountry("");
    setLevel("");
    setPosition("");
    setType("");
  };
  const deleteBenefit = (id) => {
    axios
      .delete(`${config.baseUrl}/v1/pensions/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPension();
          setVisibleDraw(false);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this Pension?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteBenefit();
      },
      onCancel() { },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Benefit?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() { },
    });
  };


  function onChangeStartDate(date, dateString) {
    console.log(date)
    setStartDateFilter(date);
  }
  function onChangeEndDate(date, dateString) {
    setEndDateFilter(date);
  }

  const convertReadableDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate
  }


  const openViewBenefitToggle = () => {
    setOpenViewBenefit(true);
  };
  const updatePensionSwitch = () => {
    setUpdateSwitch(true);
    setBenefitCreate(true);
    setVisibleDraw(false);
    setRowID(onUpdateData.id);
    setType(onUpdateData.type);
    setCategory(onUpdateData.category);
    setAmount(onUpdateData.amountBenefit);
    setLevel(onUpdateData.levels);
    setPosition(onUpdateData.positions);
    setAmtBenefit(onUpdateData.totalAmount);
    setCurrency(onUpdateData.currency);
    setBenefitCurrency(onUpdateData.benefitCurrency);
    setBenefitBillersRecord(onUpdateData?.benefitBillers);
    setRecipient(onUpdateData.recipient);
    setCountry(onUpdateData.country);
    setBenefitStatus(onUpdateData.status);
    setViewLevels(onUpdateData.levels);
    setPeriod(onUpdateData.period?.slice(0, 10));
    setViewPositions(onUpdateData.positions);
    setType(
      onUpdateData?.type === "DATA BUNDLE" ? "DATA_BUNDLE" : onUpdateData?.type
    );
    setIndStatus(onUpdateData?.status);
  };

  const changeStatusFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/pensions/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPension();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getPension();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createBenefit = () => {
    setRowID("");
    setOccuuringTimeHour("");
    setReoccuring(false);
    setOccuuringTimeMin("");
    setDayMonth("");
    setFrequency("");
    if (
      userChecklists.positionCompleted &&
      userChecklists.employeeLevelCompleted &&
      userChecklists.departmentCompleted
    ) {
      setBenefitCreate(true);
      setUpdateSwitch(false);
      setOnboardChecklist(false);
    } else {
      setOnboardChecklist(true);
    }
  };

  const resumePauseBenefit = (type) => {
    Modal.confirm({
      title: `Are you sure want to ${type === "TERMINATE"
        ? "Terminate"
        : type !== "RESUME"
          ? "Pause"
          : "Resume"
        } this Benefit Schedule?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        alterBenefitPauseResume(type);
      },
      onCancel() { },
    });
  };

  const showRunBenefit = (sch) => {
    var runNowTexxt =
      "Are you sure want to run this pension now and at scheduled?";
    var runAtScheduledText =
      "Are you sure want to run this pension at scheduled only?";
    var UnScheduledText = "Are you sure want to run this pension?";
    Modal.confirm({
      title: isScheduledBenefit
        ? sch
          ? runAtScheduledText
          : runNowTexxt
        : UnScheduledText,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        runBenefit(sch);
      },
      onCancel() { },
    });
  };

  const contribution = (data) => {
    return <div>{data.currency} {data.pensionContribution ? Intl.NumberFormat("en-US").format(data.pensionContribution) : 0}</div>
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    filterRecords(value);
  };

  const filterRecords = (input) => {
    const lowercasedInput = input.toLowerCase();
    const filtered = eligibleEmployeesBenefit.filter((record) =>
      Object.values(record).some((value) =>
        value.toString().toLowerCase().includes(lowercasedInput)
      )
    );
    setFilteredEligibleEmployees(filtered);
  };

  const onDeleteMultipleBenefits = () => {
    Modal.confirm({
      title: `Are you sure delete these Benefit(s)`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/pensions`,
            {
              benefitIds: listChecks?.map((item) => {
                return item.id;
              }),
            },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              getPension();
              setListCheck([]);
              setSelectAll(false);
              Notification("success", "Success", res.data.message);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() { },
    });
  };

  function extractDateParts(dateString) {
    const [year, month, day] = dateString.split("-");
    const yearVar = parseInt(year, 10);
    const monthVar = parseInt(month, 10);
    const dayVar = parseInt(day, 10);
    setTriggerYear(yearVar);
    setMonthVar(monthVar);
    setDayMonth(dayVar);
  }

  const onChnageDateOnce = (date, datestring) => {
    extractDateParts(datestring);
    setTriggerDayOnce(date);
  };

  const onPopulateChecks = (e) => {
    if (e.target?.checked) {
      setListCheck([...listChecks, e.target.value]);
    } else {
      setListCheck(listChecks?.filter((list) => list !== e.target?.value));
    }
  };
  const columns = [
    {
      key: "status",
      text: "",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <div class="form-check">
              <input
                class="form-check-input border-checks"
                type="checkbox"
                checked={record.checked}
                onChange={() => handleCheckboxChange(record.id)}
              />
            </div>
          </Fragment>
        );
      },
    },
    {
      key: "totalAmount",
      text: "Total Amount",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "totalPayableAmount",
      text: "Payable Amount",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "totalFee",
      text: "Total Fee",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "size",
      text: "Employees",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "period",
      text: "Period",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <div className="d-flex align-items-center">
              <span
                className={
                  {
                    SUCCESS: "badge bg-success",
                    STARTED: "badge bg-warning",
                    COMPLETED: "badge bg-success",
                    SUCCESS: "badge bg-success",
                    FAILED: "badge bg-danger",
                    IDLE: "badge bg-info",
                    PENDING: "badge bg-warning",
                    PROCESSING: "badge bg-warning",
                    TIMEOUT: "badge bg-danger",
                  }[record.status]
                }
              >
                {record.status}
              </span>
              {record?.isScheduled && (
                <i class="fa fa-recycle ml-3" style={{ color: "#000000" }}></i>
              )}
            </div>
          </Fragment>
        );
      },
    },
    {
      key: "created_date",
      text: "Date Created",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const closeViewBenefit = () => setOpenViewBenefit(false);

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => { },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => { },
      onDoubleClick: (event) => { },
    },
  ];

  const viewErrorMsg = (errMsg, e) => {
    e.preventDefault();
    setFailedErrMessages(errMsg.messages);
    setFailedMsgModal(true);
  };


  const getPensionFilter = () => {
    setLoadBenefits(true)
    axios
      .get(
        `${config.baseUrl
        }/v1/pensions/paged?page=0&size=10&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&scheduleFrequency=${statusFilter}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadBenefits(false)
        const benefitData = res.data.result;
        setTotalItems(res.data.totalPages * 10);
        setBenefitData(
          benefitData.map((data) => ({
            id: data.id,
            totalAmount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalAmount),
            country: data.country,
            levels: data.levels,
            created_date: moment(data.createdAt).format("DD-MMM-YYYY h:mm A"),
            currency: data.walletId,
            checked: false,
            benefitCurrency: data.currency,
            benefitBillers: data.benefitBillers,
            amountBenefit: data.amount ? data.amount : data?.totalAmount,
            platform: data.platform,
            positions: data.positions,
            isScheduled: data?.isScheduled,
            triggerDay: data?.triggerDay,
            triggerMonth: data?.triggerMonth,
            triggerYear: data?.triggerYear,
            benefitAmt:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.amount),
            totalAmount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalAmount),
            amount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.amount),
            fee:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalFee),
            totalEmployees: data.totalEmployees,
            recipient: data.recipient,
            type: data.type ? data.type.replace(/_+/g, " ") : "",
            status: data.status,
            frequency: data?.frequency,
            triggerDay: data?.triggerDay,
            triggerHour: data?.triggerHour,
            triggerMinute: data?.triggerMinute,
            scheduleStatus: data.scheduleStatus,
          }))
        );

      })
      .catch((err) => {
        setLoadBenefits(false)
        if (err) {
        }
      });
  };

  const getPagedFilter = (query) => {
    setLoadFilter(true)
    axios
      .post(
        `${config.baseUrl}/v1/pensions/employer/filtered/paged?${query}`,
        {
          "endDate": convertReadableDate(endDateFilter),
          employerId: sessionStorage.getItem('employer_id'),
          "scheduleFrequency": scheduleFrequencyFilter,
          "startDate": convertReadableDate(startDateFilter),
          "status": statusFilter
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadFilter(false)
        setOpenFilterModal(false)
        const benefitData = res.data.result;
        setTotalItems(res.data.totalPages * 10);
        setBenefitData(
          benefitData.map((data) => ({
            id: data.id,
            totalAmount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalAmount),
            narration: data.narration,
            totalPayableAmount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalPayableAmount),
            totalDeduction:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalDeduction),
            totalFee:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalFee),
            payGroup: data.payGroup ? data.payGroup : "NA",
            payrollDetails: data.payrollAmount,
            size: data.totalEmployees,
            checked: false,
            created_date: data?.createdAt
              ? data?.createdAt?.slice(0, 10)
              : "",
            type: data.type,
            isScheduled: data?.isScheduled,
            currency: data.walletId,
            period: data.period
              ? data.period.slice(0, 10)
              : "",

            status: data.status,
            frequency: data?.frequency,
            triggerDay: data?.triggerDay,
            triggerHour: data?.triggerHour,
            scheduleStatus: data?.scheduleStatus,
            triggerMinute: data?.triggerMinute,
            triggerMonth: data?.triggerMonth,
            triggerYear: data?.triggerYear,
          }))
        );

      })
      .catch((err) => {
        setLoadFilter(false)
        if (err) {
        }
      });
  };


  const onFilerSubmit = () => {
    setLoadFilter(true)
    axios
      .post(
        `${config.baseUrl}/v1/pensions/employer/filtered/paged?page=0&size=10`,
        {
          "endDate": convertReadableDate(endDateFilter),
          employerId: sessionStorage.getItem('employer_id'),
          "scheduleFrequency": scheduleFrequencyFilter,
          "startDate": convertReadableDate(startDateFilter),
          "status": statusFilter
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadFilter(false)
        setOpenFilterModal(false)
        const benefitData = res.data.result;
        setTotalItems(res.data.totalPages * 10);
        setBenefitData(
          benefitData.map((data) => ({
            id: data.id,
            totalAmount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalAmount),
            narration: data.narration,
            totalPayableAmount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalPayableAmount),
            totalDeduction:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalDeduction),
            totalFee:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalFee),
            payGroup: data.payGroup ? data.payGroup : "NA",
            payrollDetails: data.payrollAmount,
            size: data.totalEmployees,
            checked: false,
            created_date: data?.createdAt
              ? data?.createdAt?.slice(0, 10)
              : "",
            type: data.type,
            isScheduled: data?.isScheduled,
            currency: data.walletId,
            period: data.period
              ? data.period.slice(0, 10)
              : "",

            status: data.status,
            frequency: data?.frequency,
            triggerDay: data?.triggerDay,
            triggerHour: data?.triggerHour,
            scheduleStatus: data?.scheduleStatus,
            triggerMinute: data?.triggerMinute,
            triggerMonth: data?.triggerMonth,
            triggerYear: data?.triggerYear,
          }))
        );

      })
      .catch((err) => {
        setLoadFilter(false)
        if (err) {
        }
      });
  };



  const getPension = () => {
    axios
      .get(
        `${config.baseUrl
        }/v1/pensions/employer/paged?page=0&size=10&employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const benefitData = res.data.result;
        setTotalItems(res.data.totalPages * 10);
        setBenefitData(
          benefitData.map((data) => ({
            id: data.id,
            totalAmount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalAmount),
            narration: data.narration,
            totalPayableAmount:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalPayableAmount),
            totalDeduction:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalDeduction),
            totalFee:
              data.currency +
              " " +
              Intl.NumberFormat("en-US").format(data.totalFee),
            payGroup: data.payGroup ? data.payGroup : "NA",
            payrollDetails: data.payrollAmount,
            size: data.totalEmployees,
            checked: false,
            created_date: data?.createdAt
              ? data?.createdAt?.slice(0, 10)
              : "",
            type: data.type,
            isScheduled: data?.isScheduled,
            currency: data.walletId,
            period: data.period
              ? data.period.slice(0, 10)
              : "",

            status: data.status,
            frequency: data?.frequency,
            triggerDay: data?.triggerDay,
            triggerHour: data?.triggerHour,
            scheduleStatus: data?.scheduleStatus,
            triggerMinute: data?.triggerMinute,
            triggerMonth: data?.triggerMonth,
            triggerYear: data?.triggerYear,
          }))
        );

      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const levelLists = [];
  levelLists.push(level);

  const handleChangePosition = (value) => {
    setPosition(value);
  };

  const handleChangeLevel = (value) => {
    setLevel(value);
  };

  const addPension = (e) => {
    e.preventDefault();

    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      levels: level.length < 1 ? [] : level,
      platform: "WEB",
      employeeIds: selectedEmployees?.map((d) => d?.employeeId),
      positions: position.length < 1 ? [] : position,
      departments: departments,
      type,
      walletId: currency,
      frequency: frequency,
      isScheduled: reoccuringCheck,
      triggerDay: dayMonth,
      triggerMonth: triggerMonth,
      triggerYear: triggerYear,
      triggerHour: occuringTimeHour?.toString(),
      triggerMinute: occuringTimeMin?.toString(),
      "period": period,
      "pensionIds": [
      ]

    };
    if (data.walletId && data.period) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/pensions`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setSelectedEmployees(null);
            setSearchVal("");
            setSuccessMsg(res.data.message);
            Notification("success", "Success", res.data.message);
            setOpenbenefitSummary(false);
            setTimeout(() => {
              setSuccess(false);
              closeAdd();
              getPension();
              setBenefitCreate(false);
            }, 2000);
          } else {
            setSelectedEmployees(null);
            Notification("error", "Error", res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const updatePension = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      levels: level ? level : [],
      employeeIds: selectedEmployees?.map((d) => d?.employeeId),
      departments: departments,
      platform: "WEB",
      positions: position ? position : [],
      type,
      period: period,
      walletId: currency,
      frequency: frequency,
      isScheduled: reoccuringCheck,
      triggerMonth: triggerMonth,
      triggerYear: triggerYear,
      triggerDay: dayMonth,
      triggerHour: occuringTimeHour?.toString(),
      triggerMinute: occuringTimeMin?.toString(),
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/pensions/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setOpenbenefitSummary(false);
          setSearchVal("");
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            setSuccess(false);
            closeAdd();
            setPeriod("")
            getPension();
            setBenefitCreate(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const onChangeTab = (key) => {
    setActiveTabBenefit(key);
  };

  const itemsTab = [
    {
      key: "1",
      label: "Eligible Employees",
      children: "",
    },
    {
      key: "2",
      label: "Uneligible Employees",
      children: "",
    },
  ];

  function onChangePeriod(date, dateString) {
    setPeriod(date);
  }


  const alterBenefitPauseResume = (type) => {
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/pensions/${onUpdateData?.id}/schedule`,
        {
          status: type,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccessMsg(res.data.message);
          Notification("success", "Success", res.data.message);
          setVisibleDraw(false);
          getPension();
        } else {
          Notification("error", "Error", res.data.message);
          setErrorMsg(res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const runBenefit = (sch) => {
    var urlNow = `${config.baseUrl}/v1/pensions/run/${rowId}`;
    var urlSchedule = `${config.baseUrl
      }/v1/pensions/run/${rowId}?runNow=${false}`;
    setLoad(true);
    axios
      .put(
        sch ? urlSchedule : urlNow,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setIsScheduledBenefit(false);
          setOpenRunBenefitscheduled(false);
          Notification("success", "Success", res.data.message);
          setVisibleDraw(false);
          getPension();
        } else {
          Notification("error", "Error", res.data.message);
          setErrorMsg(res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const stopBenefit = () => {
    Modal.confirm({
      title: `Are you sure you want to stop this benefit?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoad(true);
        axios
          .delete(`${config.baseUrl}/v1/pensions/stop/${rowId}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setLoad(false);
            if (res.data.status) {
              setSuccessMsg(res.data.message);
              Notification("success", "Success", res.data.message);
              setVisibleDraw(false);
              getPension();
            } else {
              setErrorMsg(res.data.message);
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              setLoad(false);
              setServerError(true);
              setTimeout(() => {
                setServerError(false);
              }, 1500);
            }
          });
      },
      onCancel() { },
    });
  };

  const checkChangegeEtisalat = (id, e) => {
    var check = billersPlan?.find((x) => x.id == id.id);
    if (e.target.checked) {
      if (check?.id === id?.id) {
        setCheckStatusEti(true);
        setCurrentNetwork(id?.id);
      } else {
        setCheckStatusEti(false);
      }
    } else {
      setCheckStatusEti(false);
    }
  };

  const checkChangeMtn = (id, e) => {
    var check = billersPlan?.find((x) => x.id == id.id);
    if (e.target.checked) {
      if (check?.id === id?.id) {
        setCheckStatusMtn(true);
        setCurrentNetwork(id?.id);
      } else {
        setCheckStatusMtn(false);
      }
    } else {
      setCheckStatusMtn(false);
    }
  };

  const checkChangegeGlo = (id, e) => {
    var check = billersPlan?.find((x) => x.id == id.id);
    if (e.target.checked) {
      if (check?.id === id?.id) {
        setCheckStatusGlo(true);
        setCurrentNetwork(id?.id);
      } else {
        setCheckStatusGlo(false);
      }
    } else {
      setCheckStatusGlo(false);
    }
  };

  const checkChangegeAirtel = (id, e) => {
    var check = billersPlan?.find((x) => x.id == id.id);
    if (e.target.checked) {
      if (check?.id === id?.id) {
        setCheckStatusAirtel(true);
        setCurrentNetwork(id?.id);
      } else {
        setCheckStatusAirtel(false);
      }
    } else {
      setCheckStatusAirtel(false);
    }
  };

  const getPensionSummarySearch = () => {
    const data = {

      employerId: sessionStorage.getItem("employer_id"),
      levels: level.length < 1 ? [] : level,
      platform: "WEB",
      employeeIds: selectedEmployees?.map((d) => d?.employeeId),
      positions: position.length < 1 ? [] : position,
      departments: departments,
      type,
      walletId: currency,
      frequency: frequency,
      isScheduled: reoccuringCheck,
      triggerDay: dayMonth,
      triggerMonth: triggerMonth,
      triggerYear: triggerYear,
      triggerHour: occuringTimeHour?.toString(),
      triggerMinute: occuringTimeMin?.toString(),
      "period": period,
      "pensionIds": [
      ]

    };

    if (data.walletId && period) {
      setLoadSearch(true);
      axios
        .post(`${config.baseUrl}/v1/pensions/summary`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadSearch(false);
          if (res.data.status) {
            setBenefitSummaryDetails(res.data.result);
            setEligibleEmployeesBenefit(res.data.result?.eligibleEmployees);
            setFilteredEligibleEmployees(res.data.result?.eligibleEmployees);
            setOpenbenefitSummary(true);
            setBenefitCreate(false);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            // setPayrollErrorResponseData(res.data.result.payRollDetails);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoadSearch(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const getPensionSummary = (e) => {
    e.preventDefault();
    console.log(amount, benefitBillers);
    const data = {
      period,
      employerId: sessionStorage.getItem("employer_id"),
      walletId: currency,
      "departments": [],
      "employeeIds": [],
      "frequency": "",
      "isScheduled": true,
      "levels": [],
      "pensionIds": [],
      "positions": [],
      "triggerDay": "",
      "triggerHour": "",
      "triggerMinute": "",
      "triggerMonth": "",
      "triggerYear": "",
    };


    if (data.walletId && period) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/pensions/summary`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setBenefitSummaryDetails(res.data.result);
            setEligibleEmployeesBenefit(res.data.result?.eligibleEmployees);
            setFilteredEligibleEmployees(res.data.result?.eligibleEmployees);
            setOpenbenefitSummary(true);
            setBenefitCreate(false);
          } else {
            setError(true);
            Notification("error", 'Error', res.data.message)
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };


  const getChecklists = () => {
    setLoadBenefits(true);
    axios
      .get(`${config.baseUrl}/v1/users/checklist`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadBenefits(false);
        if (res.data.status) {
          setUserChecklist(res.data.result);
          if (
            !res.data.result.positionCompleted ||
            !res.data.result.employeeLevelCompleted ||
            !res.data.result.departmentCompleted
          ) {
            setOnboardChecklist(true);
            return;
          }
        } else {
        }
      })
      .catch((err) => {
        setLoadBenefits(false);
        if (err) {
        }
      });
  };

  const getLevel = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employee-level/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setLevelSelect(
            res.data.result?.map((d) => ({
              value: d.level,
              label: d.level,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onChangeTime = (val) => {
    setOccuuringTimeMin(val.$m);
    setOccuuringTimeHour(val.$H);
    setScheduleTime(val);
  };

  const getPositionS = () => {
    axios
      .get(
        `${config.baseUrl}/v1/positions/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPositionSelect(
            res.data.result?.map((d) => ({
              value: d.name,
              label: d.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const editRole = (e) => {
    e.preventDefault();
  };

  const getPosition = () => {
    axios
      .get(
        `${config.baseUrl}/v1/positions/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPositionOptions(
            res.data.result?.map((d) => ({
              value: d?.name,
              label: d?.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getLevels = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employee-level/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setLevelOptions(
            res.data.result?.map((d) => ({
              value: d?.level,
              label: d?.level,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const getDepartment = () => {
    axios
      .get(
        `${config.baseUrl}/v1/departments/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setDepartmentOptions(
            res.data.result?.map((d) => ({
              value: d?.name,
              label: d?.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const employeeName = (data) => {
    return <div>{data?.firstName + ' ' + data?.lastName}</div>
  }

  useEffect(() => {
    if (!selectAll) {
      setListCheck([]);
    }
  }, [selectAll]);

  useEffect(() => {
    if (!reoccuringCheck) {
      setOccuuringTimeMin("");
      setOccuuringTimeHour("");
      setScheduleTime("00:00");
      setFrequency("");
      setDayMonth("");
    }
  }, [reoccuringCheck]);

  useEffect(() => {
    getPension();
    getChecklists();
    getDepartment();
    getPosition();
    getLevels();
    setFilteringPagination(false)
  }, []);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_info: true,
    show_pagination: false,
    show_length_menu: false,
    button: {
      excel: true,
      print: true,
    },
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const currencyOptions = currencyDatas.map((currency) => {
    return (
      <option value={currency.id}>
        {" "}
        {currency.currency}{" "}
        {currency.balance
          ? Intl.NumberFormat("en-US").format(currency.balance)
          : 0}
      </option>
    );
  });

  const onPlanChangeAirtel = (biller, e) => {
    var airtelPlan = benefitBillers?.find((d) => d?.name === biller?.name);
    if (airtelPlan?.id) {
      const plans = biller.billerPlans?.find((x) => x.id === e.target?.value);
      var arr = benefitBillers;
      let objIndex = arr.findIndex((obj) => obj.id == airtelPlan.id);
      this.arr[objIndex].billerId = biller.id;
      this.arr[objIndex].billerPlanId = plans.id;
      this.arr[objIndex].amount = plans.amount;
      this.arr[objIndex].billerPlanName = plans.name;
      this.arr[objIndex].billerName = biller.name;
    } else {
      const plans = biller.billerPlans?.find((x) => x.id === e.target?.value);
      benefitBillers.push({
        billerId: biller?.id,
        billerName: biller?.name,
        billerPlanId: plans?.id,
        amount: plans?.amount,
        billerPlanName: plans?.name,
      });
    }
  };

  const onPlanChange9mobile = (biller, e) => {
    const plans = biller.billerPlans?.find((x) => x.id === e.target?.value);
    benefitBillers.push({
      billerId: biller?.id,
      billerName: biller?.name,
      amount: plans?.amount,
      billerPlanId: plans?.id,
      billerPlanName: plans?.name,
    });
  };

  const onPlanChange = (biller, e) => {
    const plans = biller.billerPlans?.find((x) => x.id === e.target?.value);
    const findPlanInBillerSelect = benefitBillers?.find(
      (x) => x?.billerId === biller?.id
    );
    console.log(plans);
    console.log(findPlanInBillerSelect);
    console.log(benefitBillers);
    if (findPlanInBillerSelect?.billerPlanId) {
      let objIndex = benefitBillers.findIndex(
        (obj) => obj.billerPlanId == findPlanInBillerSelect?.billerPlanId
      );
      benefitBillers[objIndex].billerId = findPlanInBillerSelect.billerId;
      benefitBillers[objIndex].billerName = findPlanInBillerSelect?.billerName;
      benefitBillers[objIndex].billerPlanId = plans?.id;
      benefitBillers[objIndex].amount = plans?.amount;
      benefitBillers[objIndex].billerPlanName = plans?.name;
    } else {
      benefitBillers.push({
        billerId: biller?.id,
        billerName: biller?.name,
        billerPlanId: plans?.id,
        amount: plans?.amount,
        billerPlanName: plans?.name,
      });
    }
  };

  const onPlanChangeGlo = (biller, e) => {
    const plans = biller.billerPlans?.find((x) => x.id === e.target?.value);

    benefitBillers.push({
      amount: plans?.amount,

      billerId: biller?.id,
      billerName: biller?.name,
      billerPlanId: plans?.id,
      billerPlanName: plans?.name,
    });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const filterPaged = `page=${page - 1
      }&size=${pageSize}`
    const queryString = `?page=${page - 1
      }&size=${pageSize}&employerId=${sessionStorage.getItem("employer_id")}`;
    !filteringPagination ? getPaged(queryString) : getPagedFilter(filterPaged);
  };

  const closeListup = () => {
    setOpenbenefitSummary(false);
    closeAdd();
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/pensions/paged${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const benefitData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setBenefitData(
            benefitData.map((data) => ({
              id: data.id,
              totalAmount:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalAmount),
              narration: data.narration,
              totalPayableAmount:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalPayableAmount),
              totalDeduction:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalDeduction),
              totalFee:
                data.currency +
                " " +
                Intl.NumberFormat("en-US").format(data.totalFee),
              payGroup: data.payGroup ? data.payGroup : "NA",
              payrollDetails: data.payrollAmount,
              size: data.totalEmployees,
              checked: false,
              created_date: data?.createdAt
                ? data?.createdAt?.slice(0, 10)
                : "",
              type: data.type,
              isScheduled: data?.isScheduled,
              currency: data.walletId,
              period: data.period
                ? data.period.slice(0, 10)
                : "",

              endPeriod: data.endPeriod ? data.endPeriod.slice(0, 10) : "",
              startEndPeriodBust:
                data?.startPeriod?.slice(0, 10) +
                "/" +
                data?.endPeriod?.slice(0, 10),
              fullEndPeriod: data.endPeriod ? data.endPeriod : "",
              fullStartPeriod: data.startPeriod ? data.startPeriod : "",
              status: data.status,
              frequency: data?.frequency,
              triggerDay: data?.triggerDay,
              triggerHour: data?.triggerHour,
              scheduleStatus: data?.scheduleStatus,
              triggerMinute: data?.triggerMinute,
              triggerMonth: data?.triggerMonth,
              triggerYear: data?.triggerYear,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const role = sessionStorage.getItem("activeRole");

  const approveBenefitFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/pensions/approve/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPension();
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            setSuccess(false);
            setStatusMsg("");
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
          setError(true);
          setVisibleDraw(false);
          setTimeout(() => {
            setError(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const declineBenefitFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/pensions/decline/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSuccessDecline(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPension();
          setTimeout(() => {
            setSuccessDecline(false);
            setStatusMsg("");
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setError(true);
          setVisibleDraw(false);
          setTimeout(() => {
            setError(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const updatePensionSchedule = () => {
    const data = {
      frequency: frequency,
      status: "UPDATE",
      triggerDay: dayMonth,
      triggerHour: occuringTimeHour,
      triggerMinute: occuringTimeMin,
      triggerMonth: triggerMonth,
      triggerYear: triggerYear,
    };
    setLoadUpdateSchedule(true);

    axios
      .put(`${config.baseUrl}/v1/pensions/${rowId}/schedule`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadUpdateSchedule(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPension();
          setOpenUpdateScheduleModal(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdateSchedule(false);
        if (err) {
        }
      });
  };

  const approveStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to Approve this pension?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        approveBenefitFunc();
      },
      onCancel() { },
    });
  };

  const updateSchedule = () => {
    axios
      .put(
        `${config.baseUrl}/v1/payrolls/${rowId}/schedule`,
        {
          frequency: frequency,
          status: indStatus,
          triggerDay: dayMonth,
          triggerHour: occuringTimeHour,
          triggerMinute: occuringTimeMin,
          triggerMonth: dayMonth,
          triggerYear: triggerYear,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const declineStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to decline this pension?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        declineBenefitFunc();
      },
      onCancel() { },
    });
  };

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              {!loadBenefits && (
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 class="card-title">Pension</h5>
                    <button
                      className="btn btn-info m-b-xs mr-2"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={createBenefit}
                    >
                      Create Pension
                    </button>{" "}
                  </div>

                  <div className="" style={{ position: 'relative' }}>
                    <div className="filter-barz">
                      <div className="d-flex align-items-center">
                        <div className="">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{ background: "#1FC157", color: "white", height: '43px' }}
                            onClick={() => {
                              setOpenFilterModal(true)
                              setFilteringPagination(true)
                            }}
                          >
                            Filter
                          </button>
                        </div>


                      </div>
                    </div>
                    {listChecks?.length > 0 && (
                      <div style={{ position: "relative" }}>
                        <div className="cl-delete-trash">
                          <div className="d-flex align-items-center">
                            <div
                              className="bord-delete"
                              onClick={onDeleteMultipleBenefits}
                            >
                              {" "}
                              <i
                                style={{ color: "#1fc157" }}
                                className="fa fa-trash"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div style={{ position: "relative" }}>
                      <div class="form-check tb-check-head">
                        <input
                          class="form-check-input border-checks"
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </div>
                    </div>{" "}
                    <div style={{ overflowX: "auto" }} className="pb-4">
                      <ReactDatatable
                        config={configTable}
                        records={benefitData}
                        columns={columns}
                        extraButtons={extraButtons}
                      />
                    </div>
                    {benefitData?.length > 0 && (
                      <div className="text-center pagination-part pt-5">
                        <Pagination
                          current={page}
                          total={totalItems}
                          defaultPageSize={10}
                          itemRender={itemRender}
                          onChange={pagination}
                        />
                      </div>
                    )}
                    <br />
                  </div>
                </div>
              )}
              {loadBenefits && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div className="text-right d-flex justify-content-end align-items-center">
          <span
            className={
              {
                true: "badge bg-success",
                false: "badge bg-danger",
              }[isScheduledBenefit]
            }
          >
            {isScheduledBenefit ? "SCHEDULED BENEFIT" : ""}
          </span>
        </div>
        <Link to={{ pathname: "/pension-details", state: { pensionId: rowId } }}>
          <div style={{ cursor: "pointer", color: "#000000" }}>
            View Pension Detail
          </div>
        </Link>



        {
          {
            IDLE: (
              <>
                <hr />
                <div
                  style={{ cursor: "pointer", color: "#000000" }}
                  onClick={() => {
                    closeDrawer();
                    if (isScheduledBenefit) {
                      setOpenRunBenefitscheduled(true);
                    } else {
                      showRunBenefit();
                      setOpenRunBenefitscheduled(false);
                    }
                  }}
                >
                  Run Pension
                </div>

                {role === "EMPLOYER" && (
                  <>
                    <hr />
                    <div
                      style={{ cursor: "pointer", color: "#000000" }}
                      onClick={showDeleteConfirm}
                    >
                      Delete Pension
                    </div>
                  </>
                )}
              </>
            ),
            PENDING: (
              <>
                {role === "EMPLOYER" && (
                  <>
                    <hr />
                    <div
                      style={{ cursor: "pointer", color: "#000000" }}
                      onClick={approveStatusConfirm}
                    >
                      Approve Pension
                    </div>
                    <hr />
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={declineStatusConfirm}
                    >
                      Decline Pension
                    </div>
                  </>
                )}
              </>
            ),
            STARTED: (
              <>
                {role === "EMPLOYER" && (
                  <>
                    <hr />
                    <div
                      style={{ cursor: "pointer", color: "#000000" }}
                      onClick={stopBenefit}
                    >
                      Stop Pension
                    </div>
                  </>
                )}
              </>
            ),
            PROCESSING: <></>,
            COMPLETED: <></>,
          }[indStatus]
        }

        <hr />

        <div
          style={{ cursor: "pointer", color: "#000000" }}
          onClick={updatePensionSwitch}
        >
          Update Pension
        </div>
        <hr />
        {isScheduledBenefit && (
          <>
            <div
              style={{ cursor: "pointer", color: "#000000" }}
              onClick={resumePauseBenefit.bind(this, "TERMINATE")}
            >
              Terminate Schedule
            </div>
            <hr />
            <div
              className="text-menu-dark-link"
              style={{ cursor: "pointer", color: "#000000" }}
              onClick={() => {
                setOpenUpdateScheduleModal(true);
                setVisibleDraw(false);
              }}
            >
              Update Schedule
            </div>

            {indStatus !== "IDLE" && (
              <div>
                <hr />
                <div
                  style={{ cursor: "pointer", color: "#000000" }}
                  onClick={resumePauseBenefit.bind(
                    this,
                    onUpdateData?.scheduleStatus === "RESUME"
                      ? "PAUSE"
                      : "RESUME"
                  )}
                >
                  {onUpdateData?.scheduleStatus === "RESUME"
                    ? "Pause  Schedule"
                    : "Resume  Schedule"}
                </div>
              </div>
            )}
          </>
        )}
      </Drawer>

      <Modal
        title={"Pension Details"}
        visible={openBenficiarySummary}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeListup}
        width={1500}
      >
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card">
              <div class="">
                <div class="row">
                  <div class="col-md-3 col-12">
                    <div class="card pb-2">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-white">Eligible Employees</div>
                        <div className="text-center pb-3 text-white h5 font-weight-bold">
                          {BenefitSummaryDetails?.eligibleEmployees
                            ? BenefitSummaryDetails?.eligibleEmployees?.length
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="card pb-2">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-white">Uneligible Employees</div>
                        <div className="text-center pb-3 text-white h5 font-weight-bold">
                          {BenefitSummaryDetails?.uneligibleEmployees
                            ? BenefitSummaryDetails?.uneligibleEmployees?.length
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="card pb-2">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-white"> Total Amount</div>
                        <div className="text-center pb-3 text-white h5 font-weight-bold">
                          {BenefitSummaryDetails?.currency}{" "}
                          {BenefitSummaryDetails?.totalAmount
                            ? BenefitSummaryDetails?.totalAmount
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="card pb-2">
                      <div
                        class="card-body"
                        style={{
                          background: "#1fc157",
                          color: "white",
                          textAlign: "center",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="pb-3 text-white"> Service Fee</div>
                        <div className="text-center pb-3 text-white h5 font-weight-bold">
                          {BenefitSummaryDetails?.currency}{" "}
                          {BenefitSummaryDetails?.totalFee
                            ? BenefitSummaryDetails?.totalFee
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  justify-content-between align-items-center">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={(e) => setReoccuring(e.target?.checked)}
                      checked={reoccuringCheck}
                    />
                    <label
                      class="form-check-label font-weight-bold"
                      for="flexCheckDefault"
                    >
                      <i
                        class="fa fa-recycle  "
                        style={{ color: "#000000" }}
                      ></i>{" "}
                      Schedule Pension
                    </label>
                  </div>
                  <div>
                    {updateSwitch && (
                      <button
                        type="submit"
                        class="btn btn-primary"
                        style={{ background: "#1FC157", color: "white" }}
                        onClick={updatePension}
                      >
                        {load ? (
                          <ThreeDots
                            visible={load}
                            height="20"
                            width="50"
                            color="#ffffff"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          "Update Pension"
                        )}
                      </button>
                    )}
                    {!updateSwitch && (
                      <button
                        type="submit"
                        class="btn btn-primary"
                        style={{ background: "#1FC157", color: "white" }}
                        onClick={addPension}
                      >
                        {load ? (
                          <ThreeDots
                            visible={load}
                            height="20"
                            width="50"
                            color="#ffffff"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          "Create Pension"
                        )}
                      </button>
                    )}
                  </div>
                </div>

                {reoccuringCheck && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="">
                        <label>Frequency</label>
                        <div className="w-100">
                          <Select
                            mode="single"
                            value={frequency}
                            style={{
                              width: "150px",
                              marginRight: "1rem",
                            }}
                            onChange={(val) => {
                              setFrequency(val);
                              setScheduleTime("00:00");
                              setDayMonth("");
                            }}
                            options={frequencyTypes?.map((d) => ({
                              value: d?.value,
                              label: d?.code,
                            }))}
                          />
                        </div>
                      </div>

                      {frequency === "MONTHLY" && (
                        <div className=",t-3">
                          <label>Day of Month</label>
                          <div className="w-100">
                            <Select
                              mode="single"
                              value={dayMonth}
                              style={{
                                width: "150px",
                                marginRight: "1rem",
                              }}
                              onChange={(val) => {
                                setDayMonth(val);
                              }}
                              options={[
                                { label: "1", value: "1" },
                                { label: "2", value: "2" },
                                { label: "3", value: "3" },
                                { label: "4", value: "5" },
                                { label: "6", value: "6" },
                                { label: "7", value: "7" },
                                { label: "8", value: "8" },
                                { label: "9", value: "9" },
                                { label: "10", value: "10" },
                                { label: "11", value: "11" },
                                { label: "12", value: "12" },
                                { label: "13", value: "13" },
                                { label: "14", value: "14" },
                                { label: "15", value: "15" },
                                { label: "16", value: "16" },
                                { label: "17", value: "17" },
                                { label: "18", value: "18" },
                                { label: "19", value: "19" },
                                { label: "20", value: "20" },
                                { label: "21", value: "21" },
                                { label: "22", value: "22" },
                                { label: "23", value: "23" },
                                { label: "24", value: "24" },
                                { label: "25", value: "25" },
                                { label: "26", value: "26" },
                                { label: "27", value: "27" },
                                { label: "28", value: "28" },
                                { label: "29", value: "29" },
                                { label: "30", value: "30" },
                                { label: "31", value: "31" },
                              ]}
                            />
                          </div>
                        </div>
                      )}
                      {frequency === "WEEKLY" && (
                        <div className=";t-3">
                          <label>Day of Week</label>
                          <div className="w-100">
                            <Select
                              mode="single"
                              value={dayMonth}
                              style={{
                                width: "150px",
                                marginRight: "1rem",
                              }}
                              onChange={(val) => {
                                setDayMonth(val);
                              }}
                              options={[
                                { label: "Monday", value: "MONDAY" },
                                { label: "Tuesday", value: "TUESDAY" },
                                { label: "Wednesday", value: "WEDNESDAY" },
                                { label: "Thursday", value: "THURSDAY" },
                                { label: "Friday", value: "FRIDAY" },
                                { label: "Saturday", value: "SATURDAY" },
                                { label: "Sunday", value: "SUNDAY" },
                              ]}
                            />
                          </div>
                        </div>
                      )}
                      {frequency === "ONCE" && (
                        <div className="mt-3">
                          <label>Select Day</label>
                          <div className="w-100">
                            <DatePicker
                              onChange={onChnageDateOnce}
                              value={
                                triggerDayOnce
                                  ? dayjs(triggerDayOnce, dateFormat)
                                  : ""
                              }
                              style={{
                                width: "150px",
                                height: "3.1rem",
                                borderRadius: "10px",
                                marginRight: "1rem",
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {frequency && (
                        <div className={frequency === "ONCE" ? "pt-3" : "pt-2"}>
                          <label>Select Time of Day</label>
                          <div className="w-100">
                            <TimePicker
                              onChange={onChangeTime}
                              showSecond={false}
                              value={dayjs(scheduledTime, "HH:mm")}
                              format={"HH:mm"}
                              style={{
                                width: "150px",
                                height: "3.1rem",
                                borderRadius: "10px",
                                marginRight: "1rem",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <br />
                <br />
                <Tabs
                  defaultActiveKey="1"
                  items={itemsTab}
                  onChange={onChangeTab}
                />
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <input
                        type="text"
                        className="form-control w-100"
                        value={searchVal}
                        id="exampleInputFirstName"
                        placeholder="Search Employee..."
                        onChange={handleSearch}
                      />
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="w-100 mr-3">
                          <Select
                            mode="multiple"
                            style={{
                              width: "300px",
                              marginRight: "1rem",
                            }}
                            placeholder="Filter by Departments"
                            onChange={(val) => {
                              setDepartment(val);
                            }}
                            options={departmentOptions}
                          />
                        </div>
                        <div className="w-100 mr-3">
                          <Select
                            mode="multiple"
                            style={{
                              width: "300px",
                              marginRight: "1rem",
                            }}
                            placeholder="Filter by Positions"
                            onChange={(val) => {
                              setPosition(val);
                            }}
                            options={positionOptions}
                          />
                        </div>
                        <div className="w-100">
                          <Select
                            mode="multiple"
                            style={{
                              width: "300px",
                              marginRight: "1rem",
                            }}
                            placeholder="Filter by Employee Level"
                            onChange={(val) => {
                              setLevel(val);
                            }}
                            options={levelOptions}
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{ background: "#1FC157", color: "white" }}
                            onClick={getPensionSummarySearch}
                          >
                            {loadSearch ? "Filtering..." : "Filter"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {activeBenefitTab === "1" && (
                  <DataTable
                    value={filteredEligibleEmployees}
                    tableStyle={{ minWidth: "30rem" }}
                    paginator
                    rows={50}
                    rowsPerPageOptions={[50, 100, 200]}
                    selectionMode={"checkbox"}
                    selection={selectedEmployees}
                    onSelectionChange={(e) => {
                      if (e.value) {
                        setSelectedEmployees(e.value);
                      }
                    }}
                    dataKey="employeeId"
                  >
                    <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "3rem" }}
                    ></Column>

                    <Column
                      field="employeeCode"
                      header="Employee Code"
                    ></Column>
                    <Column
                      field="employeeName"
                      header="Employee Name"
                      body={employeeName}
                    ></Column>

                    <Column field="pensionContribution" header="Total Contribution" body={contribution}
                    ></Column>

                    <Column field="pfaName" header="PFA Name"></Column>
                    <Column field="rsaPin" header="PFA RSA PIN"></Column>
                    <Column field="position" header="Position"></Column>
                    <Column field="level" header="Level"></Column>
                    <Column field="department" header="Department"></Column>
                  </DataTable>
                )}
                {activeBenefitTab === "2" && (
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Employee Code</th>

                          <th scope="col">Employee Name</th>
                          <th scope="col">Employee Number</th>

                          <th scope="col"> Email</th>
                          <th scope="col"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BenefitSummaryDetails?.uneligibleEmployees?.map(
                          (data) => {
                            return (
                              <tr>
                                <td>{data?.employeeCode}</td>
                                <td>
                                  {data?.firstName + " " + data?.lastName}
                                </td>
                                <td>{data?.mobile}</td>

                                <td>{data?.workEmail}</td>
                                <td>
                                  <button
                                    onClick={viewErrorMsg.bind(this, data)}
                                    style={{
                                      background: "red",
                                      color: "white",
                                      border: "1px solid red",
                                    }}
                                    size="small"
                                  >
                                    View Error
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <br />
                <div className="d-flex justify-content-end align-items">
                  {updateSwitch && (
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={updatePension}
                    >
                      {load ? (
                        <ThreeDots
                          visible={load}
                          height="20"
                          width="50"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Update Pension"
                      )}
                    </button>
                  )}
                  {!updateSwitch && (
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={addPension}
                    >
                      {load ? (
                        <ThreeDots
                          visible={load}
                          height="20"
                          width="50"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Create Pension"
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={!updateSwitch ? "Create Pension" : "Update Pension"}
        visible={benefitCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {!updateSwitch ? (
            <div>
              <div class="mb-3">
                <label class="form-label">Preferred Wallet Currency</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCurrency(e.target.value)}
                  value={currency}
                >
                  <option selected>Select </option>
                  {currencyOptions}
                </select>
              </div>
              <div className="mb-3">
                <label class="form-label">Period</label>
                <DatePicker
                  onChange={onChangePeriod}
                  value={period}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="Period"
                />
              </div>
            </div>
          ) : (
            <div>
              <div class="mb-3">
                <label class="form-label">Preferred Wallet Currency</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCurrency(e.target.value)}
                  value={currency}
                >
                  <option selected>Select </option>
                  {currencyOptions}
                </select>
              </div>
              <div className="mb-3">
                <label class="form-label">Period</label>
                <DatePicker
                  onChange={onChangePeriod}
                  defaultValue={dayjs(period, dateFormat)}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="Period"
                />
              </div>

            </div>
          )}

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={getPensionSummary}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={"View Benefit"}
        visible={openViewBenefit}
        okText="Submit"
        footer={false}
        maskClosable={false}
        width={550}
        onCancel={closeViewBenefit}
      >
        <div className="container">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p>Benefit Type</p>
              <p>{type?.replace("_", " ")}</p>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <p> Amount</p>
              <p>
                {benefitCurrency} {amount}
              </p>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <p>Total Amount</p>
              <p>{amountBenefit}</p>
            </div>
            <hr />
            {type === "DATA_BUNDLE" && (
              <div>
                <div>Plans</div>
                <ul>
                  {benefitBillerrecords?.map((dt) => {
                    return (
                      <li>
                        <div className="">
                          <div> - {dt?.billerPlanName}</div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {viewpositions?.length > 0 && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Positions</p>
                  <div>
                    <ul>
                      {viewpositions?.map((s) => {
                        return <li key={s}>{s}</li>;
                      })}
                    </ul>
                  </div>
                </div>
                <hr />
              </>
            )}
            {viewLevels?.length > 0 && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Levels</p>
                  <div>
                    <ul>
                      {viewLevels?.map((s) => {
                        return <li key={s}>{s}</li>;
                      })}
                    </ul>
                  </div>
                </div>
                <hr />
              </>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title="Error Messages"
        visible={failedMsgModal}
        okText="Skip"
        onCancel={closeFailedMsg}
        maskClosable={false}
        footer={false}
      >
        <div className="container">
          {failedErrMsgs.map((msg) => {
            return (
              <div className="pb-2">
                <Alert message={msg} type="error" showIcon />
              </div>
            );
          })}
        </div>
      </Modal>

      <Modal
        title={"Filter Records"}
        visible={openFilterModal}
        footer={false}
        width={500}
        onCancel={() => {
          setOpenFilterModal(false)
          setStatusFilter("")
          setPositionsSelected("")
          setDepartmentSelected("")
          setEmployeeLevelSelected("")
          setPositionsSelected("")
          setBenefitTypeFilter("")
          setScheduleFrequencyFilter("")
        }}
        maskClosable={false}
      >
        {loadFilter && (
          <div className="text-center">
            <Spin />{" "}
          </div>
        )}
        <div className="">
          <div>
            <div>
              <div className="mb-3">
                <label>Start Date</label>

                <DatePicker
                  onChange={onChangeStartDate}
                  value={startDateFilter}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="Start Date"
                />
              </div>
              <div className="mb-3">
                <label>End Date</label>
                <DatePicker
                  onChange={onChangeEndDate}
                  value={endDateFilter}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="End Date"
                />
              </div>

              {/* <div className="mb-3">
                <label>Employee Type</label>
                <Select
                  mode="multiple"
                  value={employeeTypeSelected}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    marginRight: "1rem",
                  }}
                  onChange={(val) => {
                    console.log(val)
                    setEmployeeTypeSelected(val)
                  }}
                  options={employeeTypeData?.map((d) => ({
                    value: d.value,
                    label: d.value
                  }))}
                />
              </div> */}
              <div className="mb-3">
                <label>Schedule Frequency</label>
                <Select
                  mode="multiple"
                  value={scheduleFrequencyFilter}
                  style={{
                    width: "100%",
                    marginRight: "1rem",
                    height: "3.3rem",

                  }}
                  onChange={(val) => {
                    console.log(val)
                    setScheduleFrequencyFilter(val)
                  }}
                  options={[
                    {
                      value: "WEEKLY",
                      label: "WEEKLY"
                    },
                    {
                      value: "MONTHLY",
                      label: "MONTHLY"
                    },
                    {
                      value: "YEARLY",
                      label: "YEARLY"
                    }
                  ]}
                />
              </div>

              <div className="mb-3">
                <label>Status</label>
                <Select
                  defaultValue="Status"
                  value={statusFilter}
                  style={{
                    width: "100%",

                  }}
                  onChange={(val) => {
                    setStatusFilter(val)
                  }}
                  options={statusTypes?.map((d) => ({
                    value: d?.code,
                    label: d?.value?.replace("_", " ")
                  }))}
                />
              </div>
              <br />
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={onFilerSubmit}
                  style={{ background: "#1FC157", color: "white" }}
                >
                  Submit
                </button>
              </div>

            </div>
          </div>
        </div>

      </Modal>

      <Modal
        title="Update Schedule"
        visible={updateScheduleModal}
        okText="Skip"
        onCancel={() => {
          setOpenUpdateScheduleModal(false);
        }}
        maskClosable={false}
        footer={false}
      >
        <div className="container">
          <div className="">
            <div className="">
              <label>Frequency</label>
              <Select
                mode="single"
                value={frequency}
                style={{
                  width: "100%",
                  marginRight: "1rem",
                }}
                onChange={(val) => {
                  setFrequency(val);
                  setScheduleTime("00:00");
                  setDayMonth("");
                }}
                options={frequencyTypes?.map((d) => ({
                  value: d?.value,
                  label: d?.code,
                }))}
              />
            </div>
          </div>
          <br />
          {frequency === "MONTHLY" && (
            <div className="">
              <label>Day of Month</label>
              <div className="w-100">
                <Select
                  mode="single"
                  value={dayMonth}
                  style={{
                    width: "100%",
                    marginRight: "1rem",
                  }}
                  onChange={(val) => {
                    setDayMonth(val);
                  }}
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "5" },
                    { label: "6", value: "6" },
                    { label: "7", value: "7" },
                    { label: "8", value: "8" },
                    { label: "9", value: "9" },
                    { label: "10", value: "10" },
                    { label: "11", value: "11" },
                    { label: "12", value: "12" },
                    { label: "13", value: "13" },
                    { label: "14", value: "14" },
                    { label: "15", value: "15" },
                    { label: "16", value: "16" },
                    { label: "17", value: "17" },
                    { label: "18", value: "18" },
                    { label: "19", value: "19" },
                    { label: "20", value: "20" },
                    { label: "21", value: "21" },
                    { label: "22", value: "22" },
                    { label: "23", value: "23" },
                    { label: "24", value: "24" },
                    { label: "25", value: "25" },
                    { label: "26", value: "26" },
                    { label: "27", value: "27" },
                    { label: "28", value: "28" },
                    { label: "29", value: "29" },
                    { label: "30", value: "30" },
                    { label: "31", value: "31" },
                  ]}
                />
              </div>
              <br />
            </div>
          )}
          {frequency === "WEEKLY" && (
            <div className="">
              <label>Day of Week</label>
              <div className="w-100">
                <Select
                  mode="single"
                  value={dayMonth}
                  style={{
                    width: "100%",
                    marginRight: "1rem",
                  }}
                  onChange={(val) => {
                    setDayMonth(val);
                  }}
                  options={[
                    { label: "Monday", value: "MONDAY" },
                    { label: "Tuesday", value: "TUESDAY" },
                    { label: "Wednesday", value: "WEDNESDAY" },
                    { label: "Thursday", value: "THURSDAY" },
                    { label: "Friday", value: "FRIDAY" },
                    { label: "Saturday", value: "SATURDAY" },
                    { label: "Sunday", value: "SUNDAY" },
                  ]}
                />
              </div>
              <br />
            </div>
          )}
          {frequency === "ONCE" && (
            <div className="mb-3">
              <label>Select Day</label>
              <div className="w-100">
                <DatePicker
                  value={
                    triggerDayOnce ? dayjs(triggerDayOnce, dateFormat) : ""
                  }
                  onChange={onChnageDateOnce}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                />
              </div>
            </div>
          )}
          <div className="mb-3">
            <label>Select Time of Day</label>
            <div className="w-100">
              <TimePicker
                onChange={onChangeTime}
                showSecond={false}
                value={dayjs(scheduledTime, "HH:mm")}
                format={"HH:mm"}
                style={{
                  width: "100%",
                  height: "3.1rem",
                  borderRadius: "10px",
                  marginRight: "1rem",
                }}
              />
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={updatePensionSchedule}
            >
              {loaUpdateSchedule ? (
                <ThreeDots
                  visible={loaUpdateSchedule}
                  height="20"
                  width="50"
                  color="#ffffff"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Update "
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Run Pension"
        open={openRuunBenefitschedule}
        footer={false}
        width={500}
        onCancel={() => {
          setOpenRunBenefitscheduled(false);
        }}
      >
        <p className="text-center">
          Are you sure you want to run this Pension ?
        </p>
        <br />
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  background: "red",
                  color: "white",
                  marginRight: "1rem",
                }}
                onClick={() => {
                  setOpenRunBenefitscheduled(false);
                }}
              >
                Cancel{" "}
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  background: "#1FC157",
                  color: "white",
                  marginRight: "1rem",
                }}
                onClick={showRunBenefit.bind(this, false)}
              >
                Run Now{" "}
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                style={{ background: "#1FC157", color: "white" }}
                onClick={showRunBenefit.bind(this, true)}
              >
                Run At Schedule{" "}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={onboardChecklist}
        okText="Skip"
        maskClosable={false}
        footer={false}
        width={600}
        onCancel={onCancelOnboardChecklist}
        onOk={onCancelOnboardChecklist}
      >
        <div className="container">
          <div className="check-area pt-2">
            <div className="container">
              <h4 className="text-center" style={{ color: "#1FC157" }}>
                Welcome to SalarioPay!
              </h4>
              <p style={{ color: "#1FC157", whiteSpace: "nowrap" }}>
                Are you ready to experience the next generation payroll for your
                bussiness
              </p>
              <hr />
              <div>
                <h5 className="text-center pt-3">Onboarding Checklist</h5>
                <p className="text-center">
                  Kindly complete the checklist below to get started
                </p>
              </div>

              <div className="check-area pt-2">
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.positionCompleted
                        ? userChecklists.positionCompleted
                        : ""
                    }
                  >
                    Add Position
                  </Checkbox>
                  <Link
                    to={{
                      pathname: "/manage-position",
                      state: { auto: true },
                    }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Add
                    </Button>
                  </Link>
                </div>
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.departmentCompleted
                        ? userChecklists.departmentCompleted
                        : ""
                    }
                  >
                    Add Department
                  </Checkbox>
                  <Link
                    to={{
                      pathname: "/manage-department",
                      state: { auto: true },
                    }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Add
                    </Button>
                  </Link>
                </div>
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.employeeLevelCompleted
                        ? userChecklists.employeeLevelCompleted
                        : ""
                    }
                  >
                    Add Employee Level
                  </Checkbox>
                  <Link
                    to={{ pathname: "/employee-level", state: { auto: true } }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Add
                    </Button>
                  </Link>
                </div>
                <br />
                <hr />
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    onClick={onCancelOnboardChecklist}
                    style={{
                      background: "#1FC157",
                      color: "white",
                      width: "75px",
                    }}
                    size="small"
                  >
                    Skip{" "}
                  </Button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
